<template>
  <div>
    <component :is='contentComponentBySessionType'
      :show-session-content-select='true'
      :show-session-today-watched-time='false'
      :show-session-speakers='false'
      :show-session-title-top='true'
      :show-session-details-full='true'
      :show-session-details-short='false'
      :show-session-topics='false'
      :show-sssion-other-sessions='true'
      :show-session-questions-box='false'
      :show-session-remaining-sessions='true'
      :show-session-troubleshoot-button='true'
      :append-watermark-to-video-player='false' />
    <div v-html='selectedSessionConfigOtherSessionPageContent'></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LiveSession  from '@/components/sessions/v2/LiveSession.vue'
import VodSession   from '@/components/sessions/VodSession.vue'

export default {
  name: 'Session',
  components: {
    LiveSession,
    VodSession,
  },
  data () {
    return {
      currentSessionIdChecker: null,
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          this.getSingleSessionV2(this.$route.query.session_id).then(() => {
            if (this.selectedSessionConfigBlockViewingBeforeStartTime && new Date() < new Date(this.selectedSession.scheduledStartTime)) {
              this.createUserAction({
                userActionName: 'block_open_session_page_before_start_time',
                userActionData: {
                  event_id: this.showingEventId,
                  session_id: this.sessionId,
                  session_type: this.contentComponentBySessionType
                }
              })
              this.$alert(`${this.selectedSessionConfigBlockViewingBeforeStartTimeMessage}`, {
                confirmButtonText: this.selectedSessionConfigBlockViewingBeforeStartTimeButtonText,
                type: 'info',
                showClose: false
              }).then(() => {
                this.$router.push({ name: 'Programs' })
              })
            } else {
              this.createUserAction({
                userActionName: 'open_session_page',
                userActionData: {
                  event_id: this.showingEventId,
                  session_id: this.sessionId,
                  session_type: this.contentComponentBySessionType
                }
              })
              if (this.contentComponentBySessionType === 'live-session') {
                this.checkCurrentSessionId()
              }
            }
          })
          this.getSessionNotices(this.$route.query.session_id)
          this.unsubscribeFromNotices()
          this.subscribeToNotices(this.$route.query.session_id)
          this.unsubscribeFromGoto()
          this.subscribeToGoto(this.$route.query.session_id)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('sessions', [
      'selectedSession'
    ]),
    ...mapGetters('sessions', [
      'selectedSessionType',
      'selectedSessionConfigBlockViewingBeforeStartTime',
      'selectedSessionConfigBlockViewingBeforeStartTimeMessage',
      'selectedSessionConfigBlockViewingBeforeStartTimeButtonText',
      'selectedSessionConfigOtherSessionPageContent',
    ]),
    ...mapGetters('events', [
      'showingEventId'
    ]),
    sessionId () {
      return parseInt(this.$route.query.session_id)
    },
    contentComponentBySessionType () {
      if (this.selectedSessionType === 'vod') {
        return 'vod-session'
      } else {
        return 'live-session'
      }
    },
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
      'getSingleSessionV2',
      'subscribeToGoto',
      'unsubscribeFromGoto',
    ]),
    ...mapActions('sessionNotices', [
      'getSessionNotices',
      'subscribeToNotices',
      'unsubscribeFromNotices',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ]),
    checkCurrentSessionId () {
      this.currentSessionIdChecker = setInterval(() => {
        if (!this.$route.query.session_id) {
          clearInterval(this.currentSessionIdChecker)
        } else {
          this.getCurrentSessionId(this.$route.query.session_id).then((currentSessionId) => {
            if (currentSessionId) {
              // 다음 세션 있을 때
              if (parseInt(this.$route.query.session_id) !== currentSessionId) {
                // 지금 page query랑 다를 때
                this.$alert('This session has ended. Navigating to current session.', {
                  type: 'info',
                  confirmButtonText: 'Go to Current'
                }).then(() => {
                  this.$router.replace({name: 'Session', query: {session_id: currentSessionId, autoplay: true}})
                })
                setTimeout(() => {
                  this.$router.replace({name: 'Session', query: {session_id: currentSessionId, autoplay: true}}).then(() => {
                    location.reload(true)
                  })
                }, 5000)
              }
            } else {
              // 다음 세션 없을 때
              this.$alert('There are no sessions in progress. Navigating to the programs page.', {
                type: 'info',
                confirmButtonText: 'Go to Programs'
              }).then(() => {
                this.$router.replace({name: 'Programs'})
              })
              setTimeout(() => {
                this.$router.replace({name: 'Programs'}).then(() => {
                  location.reload(true)
                })
              }, 5000)
            }
          })
        }
      }, 120000) // call api per 2 minutes
    }
  },
  beforeDestroy () {
    clearInterval(this.currentSessionIdChecker)
    this.unsubscribeFromNotices()
    this.unsubscribeFromGoto()
  }
}
</script>
